import React from 'react';
import { Link } from 'react-router-dom';

const PostCard = ({ title, description, image, link, date, thumbnailOffset }) => {

    return (
        <div style={{ marginTop: 40 }}>
            <div className='row card black white-text'>
                <div className='col s12'>
                    <div className='row'>
                        <div className='col s10'>
                            <h4>{title}</h4>
                            <p>{date}</p>
                        </div>
                        <div className='col s2' style={{ marginTop: 21.28}}>
                            <Link to={link} className='right'><a href='#' className='btn-floating waves-effect waves-light white btn-large'><i className='fas fa-rocket' style={{color: 'black'}}></i></a></Link>
                        </div>
                    </div>
                </div>
                <div className='col s6' style={{ marginBottom: 10.5, paddingRight: 5.25 }}>
                    <div className='post-card-description-wrapper'>
                        <p style={{marginTop: 5}}>{description}</p>
                    </div>
                </div>
                <div className='col s6' style={{ paddingLeft: 5.25 }}>
                    <div style={{ overflow: 'hidden', height: 100, borderRadius: 5, marginBottom: 10.5, padding: 0 }}>
                        <img style={{ position: 'relative', top: thumbnailOffset[0], right: thumbnailOffset[1]
                        }} src={image} alt='thumbnail'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostCard
