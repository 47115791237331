import React from 'react';
import PostCard from './PostCard';
import HelloWorld from '../images/hello_world_thumbnail.png';
import LearnToCode from '../images/learn_to_code.jpg';
import StraightLineCalculations from '../images/straight-line_calculations.png';

const CardContainer = () => {

    let width = window.innerWidth;

    return (
        <div className={ width > 768 ? 'background' : 'background-mobile' }>
            <div className='row card-container'>
                
                <div className='col s0 l3'></div>
                
                <div className='col s12 l6' style={{ padding: 0 }}>
                    <h3><span className='highlight'>Posts;</span></h3>
                    <PostCard 
                        title='Hello World!' 
                        description="Hi, I'm Harlen 👋 I'm currently an undergraduate Accounting student with an interest in software development. Programming, automation, and data analysis are becoming increasingly important skills for Accountants to have. Most of my experience has been with the MERN stack and with Unity. The languages I'm most familiar with are Python, C#, and JavaScript."
                        link='/hello-world' 
                        date='Jun 24, 2022' 
                        image={HelloWorld} 
                        thumbnailOffset={[-90, 200]}
                    />
                    <PostCard 
                        title='So, you want to learn how to code?' 
                        description="Generally speaking, programming is all about building things. It's a tool that is, ultimately, just a means to an end. The end being an application of some kind. This is something you should keep in mind as you practice and study, because it's really easy (especially when just starting out) to develop tunnel vision in the pursuit of the most efficient code humanly possible. This isn't necessary most of the time and usually ends up being counter-productive. How necessary it is all depends on what you're trying to accomplish with a particular application. Try to be project minded!"
                        link='/so-you-want-to-learn-how-to-code' 
                        date='October 25, 2022' 
                        image={LearnToCode} 
                        thumbnailOffset={[-50, 300]}
                    />
                    <PostCard 
                        title='Depreciation vs. Amortization' 
                        description="Depreciation can be loosely defined as an accounting technique used to allocate the cost of a tangible asset (buildings, vehicles, equipment, etc.) over its useful life. Amortization can be loosely defined as an accounting technique used to allocate the cost of an intangible asset (patents, trademarks, copyrights, etc.) over its useful life. From these loose definitions, the main difference between the two techniques can be observed which is the type of assets each technique applies to."
                        link='/depreciation-vs-amortization' 
                        date='July 2, 2023' 
                        image={StraightLineCalculations} 
                        thumbnailOffset={[-200, 350]}
                    />
                </div>

                <div className='col s0 l3'></div>
            </div>

        </div>
    )
}

/* 
    <PostCard 
        title='Data Analytics for Accountants: The IMPACT Cycle' 
        description=""
        link='/data-analytics-for-accountants-the-impact-cycle' 
        date='July 2, 2023' 
        image={StraightLineCalculations} 
        thumbnailOffset={[-200, 350]}
    />
*/

export default CardContainer
