import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Post from '../components/Post';
import LearnToCode from '../images/learn_to_code.jpg';

const Post2 = () => {

    return (
        <div>
            <Header/>
            <Post 
                title='So, you want to learn how to code?' 
                date='October 25, 2022' 
                update='November 21, 2022'
                sections={['The Purpose of Coding', "What Learning to Code Looks Like", 'Coding and Project Managment', 'Why Do Different Languages Even Exist?', 'Higher-Level Topics', 'Resources and Recommendations']} 
                texts={[
                <p> Generally speaking, programming is all about building things. It's a tool that is, ultimately, just a means to an end. The end being an application of some kind. This is something you should keep in mind as you practice and study, because it's really easy (especially when just starting out) to develop tunnel vision in the pursuit of the most efficient code humanly possible. This isn't necessary most of the time and usually ends up being counter-productive. How necessary it is all depends on what you're trying to accomplish with a particular application. Try to be project minded!
                      
                <br></br><br></br>
                
                Now, there will always be times when you're looking back at your old code, and you just think to yourself, "What was I thinking?!". This is where refactoring comes in. You can read more about that in the "Higher Level Topics" section.</p>
                , 
                <p>
                There are a few popular routes people often take when learning to code. One of the most popular is to work on personal projects. The kind of projects you work on all depends on what you're most interested in. For me, I started out building websites and my interest slowly shifted into games. Now, I'm becoming more interested in data analytics.

                <br></br><br></br>

                Another popular way to learn is to solve coding problems on websites like  
                <a href='https://leetcode.com/' style={{ color: '#0080FF' }}> LeetCode </a> 
                and 
                <a href='https://www.hackerrank.com/' style={{ color: '#0080FF' }}> HackerRank</a>
                . Personally, I find learning this way to be a bit boring, but you might not! These websites are often used to prepare people for coding interviews and even as a way to practice for competitive programming. You can find links to more of these sites in the "Resources and Recommendations" section.

                <br></br><br></br>

                The last learning tool I'll mention are books on programming theory. Books like "Clean Code" by Robert Martin and "The Pragmatic Programmer" by Dave Thomas can help you develop a more structured and logical approach to programming. They're also your best bet at developing what's called a 
                <a href='https://mitcommlab.mit.edu/eecs/commkit/coding-mindset/#:~:text=An%20effective%20coding%20mindset%20begins,for%20you%20and%20your%20collaborators.' style ={{ color: '#0080FF' }}> "programming mindset"</a>
                .
                </p>
                ,
                <div> 
                    <p>
                        Programming and project managment often go hand in hand. Building anything effectively requires effective planning. Even as a beginner, I would advise that you introduce yourself to, and start getting comfortable with, project management methodologies like:
                    </p>
                    <ol style={{ listStyleType: 'circle' }}>
                        <li><a href='https://en.wikipedia.org/wiki/Waterfall_model' style={{ color: '#0080FF'}}>Waterfall</a> (most basic)</li>
                        <li><a href='https://www.agilealliance.org/agile101/' style={{ color: '#0080FF'}}>Agile</a> (most popular)</li>
                        <li><a href='https://www.scrum.org/resources/what-is-scrum' style={{ color: '#0080FF'}}>Scrum</a></li>
                    </ol>
                    <p>
                        Project managment methodologies are used, put simply, to help effectively plan out projects. They're often used to coordinate multiple people's efforts on a single project, but they're also useful for organizing the work of a single individual (like yourself). The above three methodologies are the most popular, but there are many others. Links to information on more of those will be available in the "Resources and Recommendations" section.
                    </p>
                </div>
                ,
                <p> A common question among beginners is, "Which language should I learn?" or "Which language is easiest to learn?" A better question might be, "Why do different languages even exist at all?" You could build the exact same application in C#, Java, or C++, so why even differentiate? The simple answer is specialization. Each language has special characteristics that make it more suited to performing certain tasks over other languages. One example of this is C++. C++ is widely considered to be one of the fastest languages out there, therefore it's best suited to applications where speed is of the upmost importance (think operating systems, games, browsers, etc.). 

                <br></br><br></br>
                
                But if this isn't a satisfactory answer, Python was the easiest language for me to learn. It has a number of specializations including: data visuzalition and analysis, prototyping, and even front-end web development. Python's easy-to-learn syntax has made it popular among professionals whose main focus isn't programming (such as accountants). This syntax does, however, come at a cost. Python is much slower than languages like C++ and Rust. 

                </p>
                , 
                <p>
                    Now lets get into some important, higher-level, topics. This section may seem out of place in an "Intro to Programming" style post, but being introduced to these topics now may save you a lot of time in the future. 
                    
                    <br></br><br></br>

                    The first topic, as mentioned previously, is refactoring. As per <a href='https://en.wikipedia.org/wiki/Code_refactoring' style ={{ color: '#0080FF' }}> Wikipedia</a>, refactoring can be defined as, "...the process of restructuring existing computer code—changing the factoring—without changing its external behavior." They go on to say, "Refactoring is intended to improve the design, structure, and/or implementation of the software (its non-functional attributes), while preserving its functionality." Refactoring will become more important the more complex the projects you're working on are. There are, often, multiple ways to solve programming problems, and some ways are going to be more appropriate with respect to the kind of project you're working on.

                    <br></br><br></br>

                    The next topic are what's referred to as "software design patterns". As per <a href='https://en.wikipedia.org/wiki/Software_design_pattern' style ={{ color: '#0080FF' }}> Wikipedia</a> (again), a software design pattern can be described as a "...reusable solution to a commonly occurring problem within a given context in software design." Software design patterns are often rather abstract, with each pattern being able to be used in many different programming contexts. A book that is often recommended when learning about these patterns is "Design Patterns: Elements of Reusable Object-Oriented Software" by Erich Gamma, John Vlissides, Ralph Johnson, and Richard Helm AKA The "Gang of Four" (don't ask me why). It's inevitable that you're going to run into problems that you just can't solve (or solve well). This book can help to, at least, give you an idea of what's possible when structuring your code.

                    <br></br><br></br>

                    Last, but certainly not least, are <a href='https://www.geeksforgeeks.org/data-structures/' style={{ color: '#0080FF' }}> data structures</a> and <a style={{ color: '#0080FF' }}> algorithms</a>. Used in conjunction, data strucutres and algorithms are often used to solve problems in the most efficient way possible. The efficiency of an algorithm is referred to as its <a href='https://www.freecodecamp.org/news/time-complexity-of-algorithms/#:~:text=So%2C%20the%20time%20complexity%20is,terms%20of%20the%20time%20complexity.' style={{ color: '#0080FF' }}> "time complexity"</a>. Time complexity is often expressed using what's called <a href='https://en.wikipedia.org/wiki/Big_O_notation' style={{ color: '#0080FF' }}> "big o notation"</a>. The lower an algorithm's time complexity, the faster it is, generally speaking. The type of algorithm used can mean the difference between taking 10,000 years or 5 minutes to solve the same problem. Questions on algorithms and data structures are also popular in coding interviews.
                </p>
                , 
                <div>
                    <h5>Practice Problem Sites</h5>
                    <ol style={{ listStyleType: 'circle' }}>

                        <li><a href='https://www.freecodecamp.org/' style={{ color: '#0080FF' }}>https://www.freecodecamp.org/</a> (personal favorite)</li>
                        <li><a href='https://www.codecademy.com/' style={{ color: '#0080FF' }}>https://www.codecademy.com/</a></li>
                        <li><a href='https://www.codechef.com/' style={{ color: '#0080FF' }}>https://www.codechef.com/</a></li>
                        <li><a href='https://projecteuler.net/archives' style={{ color: '#0080FF' }}>https://projecteuler.net/archives</a></li>
                    </ol>
                    <h5>Project Management Methodologies</h5>
                    <ol style={{ listStyleType: 'circle' }}>

                        <li><a href='https://en.wikipedia.org/wiki/Kanban_(development)' style={{ color: '#0080FF' }}>Kanban</a></li>
                        <li><a href='https://en.wikipedia.org/wiki/Lean_software_development' style={{ color: '#0080FF' }}>Lean</a></li>
                    </ol>
                </div>
            ]}
                thumbnail={LearnToCode}/>
            <Footer post={true}/>
        </div>
    )
}

export default Post2