import React from 'react';

let width = window.innerWidth;

const Footer = ({ post }) => {
    return (
        <div>
            { post ?

                <div className='row black footer'>
                    <div className='col s6 copyright'>
                        <b><p className='white-text'>Harlen Kinsey <i className='fas fa-copyright'></i> 2023</p></b>
                    </div>
                    <div className={width <= 768 ? 'col s6 right-align' : 'col s3 right-align'}>
                        <p className='white-text'>background image: u/astrellon3</p>
                    </div>
                </div>

                :

                <div className='row black footer'>
                    <div className='col s6 copyright'>
                        <b><p className='white-text'>Harlen Kinsey <i className='fas fa-copyright'></i> 2023</p></b>
                    </div>
                    <div className='col s6 right-align'>
                        <p className='white-text'>background image: u/astrellon3</p>
                    </div>
                </div>
            }
        </div>
    )
}


export default Footer