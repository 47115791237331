import React from 'react';

const Post = ({ title, date, update, sections, texts, thumbnail }) => {

    let width = window.innerWidth;

    return (
        <div className='background' style={{ padding: 0, height: '100%'}}>
            <div className='row'>
                {/* Just for spacing */}
                <div className='col s0 l2'>

                </div>
                {/* Header */}
                <div className={width <= 768 ? 'col s12 l7 post-border white-text text-center background-black post-mobile' : 'col s12 l7 post-border white-text text-center background-black'} style={{padding: 0}}>
                    <div className='row'>
                        <div className='col s12'>
                            <h2 className='left-align post-title'>
                                {title}
                            </h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col s12'>
                            <p className='left-align post-title'>
                                {date} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i>Updated</i>: &nbsp; {update}
                            </p>
                        </div>
                        
                    </div>

                    {/* Thumbnail */}
                    <img className='thumbnail' src={thumbnail} />

                    {/* Content */}
                    <div>
                        <div className={width > 768 ? 'row' : 'row border-bottom' }>
                            <div>
                                {sections.map((section, index) => {
                                    return (
                                        <div id={section}>
                                            <div className='white-text'>
                                                <h3 className='left-align post-title'>{section}</h3>
                                                <div className='left-align post-text' style={{ margin: 0}}>{texts[index]}</div>
                                            </div>  
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                {/* Section links */}
                <div className='col l3 background-black border-left section-link-container'>
                    <div className='row'>
                        <h5 className='white-text center-align' style={{textDecoration: 'underline', paddingTop: '20px'}}>Sections</h5>
                    </div>
                    {sections.map((section) => {

                            let link = '#' + section;

                            return (
                                <div className='left-align' style={{ padding: '0 20px'}}>
                                    <a href={link}><p>{section}</p></a>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default Post