import React from 'react';
import Header from '../components/Header';
import CardContainer from '../components/CardContainer';
import Footer from '../components/Footer';

const Home = () => {

    return (
        <div>
      
            <Header />
            <CardContainer />
            <Footer post={false}/>

        </div>
    )
}

export default Home