import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Post from '../components/Post';
import HelloWorld from '../images/hello_world_thumbnail.png';

const Post1 = () => {

    return (
        <div>
            <Header/>
            <Post 
                title='Hello World!' 
                date='June 24, 2022' 
                update='March 26, 2023'
                sections={['About Me', "Purpose", 'Topics Covered']} 
                texts={[
                <p>Hi, I'm Harlen 👋 I'm an undergraduate Accounting student with an interest in software development. Programming, automation, and data analysis are becoming increasingly important skills for accountants to have. Most of my experience has been with the MERN stack and with Unity. The languages I'm most familiar with are Python, C#, and JavaScript. If you ever feel the need to get in contact with me, you can shoot me an <a href='mailto:harlenkinsey60@gmail.com' className='blue-text'>email</a> or you can send me a message through <a href='https://www.linkedin.com/in/harlen-kinsey-9075291b1' className='blue-text'>LinkedIn</a>.</p>
                , 
                <p>The purpose of this blog is to provide readers with useful information regarding topics that are interesting in a way that is easy to understand. If you're reading this, it means we probably have similar interests! I hope you can find something useful here.
                <br></br><br></br>Thanks for reading!</p>
                , 
                <p>Topics I'd like to cover will be mostly related to programming, accounting, and project management. More fringe topics might include 3D modeling, statistics, and possibly even: philosophy, theology, and psychology.
                <br></br><br></br>Again, thanks for reading!</p>

            ]}
                thumbnail={HelloWorld}/>
            <Footer post={true}/>
        </div>
    )
}

export default Post1