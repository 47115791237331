import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Post from '../components/Post';
import StraightLineCalculations from '../images/straight-line_calculations.png';

const Post3 = () => {

    return (
        <div>
            <Header/>
            <Post 
                title='Depreciation vs. Amortization' 
                date='July 2, 2023' 
                update='September 21, 2023'
                sections={['Defintions', 'Application Methods', 'Salvage Values']} 
                texts={[
                <p>A topic that can be somewhat confusing is the distinction between depreciation and amortization. Depreciation can be defined as an accounting technique used to allocate the cost of a tangible asset (buildings, vehicles, equipment, etc.) over its useful life. Amortization can be defined as an accounting technique used to allocate the cost of an intangible asset (patents, trademarks, copyrights, etc.) over its useful life. So, the most imporant difference between the two techniques is the type of asset each applies to.</p>
                , 
                <p>Going a step further, it's important to note differences in the prevalence of application methods. Amortizing an intangible asset is almost always carried out using the straight-line method, while depreciating a tangible asset can be carried out using a myriad of different methods (including the straight-line method). Which method is used is dependant on the cost of application (in terms of overhead), as well as, the degree to which the method's rate of devaluation matches the actual devaluation of the asset throughout its useful life. For example, vehicles' fair market values often decline faster in their early years and slower in their later years. This causes the straight-line method's rate of devaluation to be incrongruent with reality.</p>
                , 
                <p>Another key difference between the two techniques is the use of salvage values. Depreciation calculations almost always take into account the estimated value of the asset upon its disposition, while salvage values aren't generally used when calculating amortization. The image above showcases this difference when calculating depreciation and amortiztion using the straight-line method.</p>

            ]}
                thumbnail={StraightLineCalculations}/>
            <Footer post={true}/>
        </div>
    )
}

export default Post3