import React from 'react';
import Home from './pages/Home';
import Post1 from './pages/Post1';
import Post2 from './pages/Post2';
import Post3 from './pages/Post3';
import Post4 from './pages/Post4';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import 'materialize-css/dist/css/materialize.min.css';
import './App.css';

function App() {

  return (

    <Router>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/hello-world' element={<Post1/>}/>
        <Route exact path='/so-you-want-to-learn-how-to-code' element={<Post2/>}/>
        <Route exact path='/depreciation-vs-amortization' element={<Post3/>}/>
      </Routes>
    </Router>

  );
}
/* <Route exact path='/data-analytics-for-accountants-the-impact-cycle' element={<Post4/>}/> */

export default App;
